import { randomColor } from 'randomcolor';
import { chartColorPallete, npsChartColorPallete } from '../static/colorData';

export const graphOptions = {
  animation: false,
  barThickness: 5,
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    tooltip: {
      enabled: true,
    },
    legend: {
      display: false,
    },
  },
  legend: {
    labels: {
      boxWidth: 10,
    },
  },
  scales: {
    x: {
      ticks: {
        color: 'black',
      },
      grid: {
        display: false,
      },
    },
    y: {
      title: {
        display: true,
        text: 'Responses',
      },
      grid: {
        display: false,
      },
      ticks: {
        color: 'black',
        callback: function(value) {
          if (value && value % 1 === 0) {
            return value;
          }
        },
      },
    },
  },
};

function customRadius(context: { dataIndex: any }) {
  const index = context.dataIndex;
  return index === 3 ? 10 : 2;
}

export const chartDataOptions = (is_dashboard = true) => {
  return {
    responsive: true,
    animation: false,
    maintainAspectRatio: !is_dashboard,
    elements: {
      point: {
        radius: customRadius,
        display: true,
      },
      line: {
        tension: 0.05,
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        position: 'nearest',
        padding: 10,
        multiKeyBackground: 'transparent',
        callbacks: {
          label: function(context) {
            let label = context.dataset.label ? ' ' + context.dataset.label : '';

            if (label) {
              label += ' : ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y + ' responses';
            }
            return label;
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: false,
          boxWidth: 4,
          height: 4,
          width: 4,
        },
      },
    },
    layout: {
      padding: {
        bottom: 15,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderDash: [8, 4],
          color: '#DBDBDB',
          drawBorder: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: is_dashboard ? 10 : 12,
          minRotation: 0,
          maxRotation: 0,
        },
      },
      y: {
        title: {
          display: true,
          text: ' Responses',
        },
        ticks: {
          precision: 0,
        },
        beginAtZero: true,
        grid: {
          display: true,
          drawBorder: false,
        },
      },
    },
  };
};

export const getChartDataSets = (idx: number, nps = false) => {
  let color = chartColorPallete[idx] ? chartColorPallete[idx] : randomColor();
  if (nps) {
    color = npsChartColorPallete[idx];
  }
  return {
    label: '',
    fill: false,
    backgroundColor: `${color}1A`,
    borderWidth: 2,
    borderColor: `${color}`,
    borderCapStyle: 'butt',
    pointBorderColor: `${color}`,
    pointBackgroundColor: `${color}`,
    pointBorderWidth: 2,
    pointRadius: 0.5,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: `${color}`,
    pointHoverBorderColor: `${color}`,
    pointHoverBorderWidth: 2,
    pointHitRadius: 10,
    data: [],
  };
};
