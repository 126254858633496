import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button, Box, CircularProgress, Theme, Grid, LinearProgress, Snackbar } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import filterImg from '../../../images/surveyPerformance/filter-icon.svg';
import aiStarImg from '../../../images/editor/ai-star.svg';

import { amplitudeEvent } from '../../../utils/amplitudeUtil';
import { RootState } from '../../../store';
import { capitalizeFirstLetter, intentImgFormat, userImgFormat } from '../../../utils/stringUtil';
import CountUp from 'react-countup';
import ReportFilter from './FilterDialog';
import { useHistory, useParams } from 'react-router-dom';
import { getReportById, updateReportById } from '../../../services/insightService';
import { IReportItem, IReportMeta, IWorkspaceTheme } from '../../../types/insight';
import { DailyChartComponent } from '../../spotlight/charts/DailyChartComponent';
import { reportInitialData, sentimentObj } from './constants';
import { createSurvey, createSurveyFromPrompt } from '../../../services/surveyService';
import moment from 'moment';
import InsightTopicDrawer from '../InsightTopicDrawer';
import { dateRangeQuery } from '../../../utils/DateUtil';
import { getDateRangeValue } from '../../spotlight/DateFilter';
import { isArray } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    title: { fontSize: '1.5rem', marginBottom: theme.spacing(4) },
    buttonSection: {
      marginTop: theme.spacing(4),
      display: 'flex',
      float: 'right',
    },
    filterButton: {
      color: theme.palette.primary.contrastText,
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.default,
      fontSize: '0.9rem',
      textTransform: 'none',
      padding: '0.3rem 0.75rem 0.3rem 0.75rem',
      fontWeight: 700,
      borderRadius: '5px',
      marginLeft: '9px',
      marginTop: '-5px',
    },
    drawerPaper: {
      backgroundColor: theme.palette.background.default,
    },
    loading: {
      color: theme.palette.primary.contrastText,
    },
    topicItem: {
      borderRadius: theme.spacing(1),
      border: '1px solid rgba(214, 209, 222, 0.38)',
      padding: theme.spacing(3.75),
    },
    aiSurveyBox: {
      backgroundColor: '#fefbf4',
      padding: theme.spacing(2),
      border: '1px solid rgba(214, 209, 222, 0.38)',
    },
    violetText: {
      color: '#450f58',
    },
    aiSurveyBtn: {
      borderRadius: theme.spacing(1),
      border: '1px solid rgba(214, 209, 222, 0.38)',
      padding: theme.spacing(1),
      backgroundColor: 'white',
      cursor: 'pointer',
      width: '158px',
      marginLeft: theme.spacing(1.5),
    },
    progress: {
      height: '7px',
      borderRadius: theme.spacing(1),
      backgroundColor: '#B8A8CD',
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: theme.palette.info.contrastText,
      },
    },
    stickyLoading: {
      color: theme.palette.common.white,
    },
    representativeText: {
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: '#5e5e5e',
      marginBottom: 5,
    },
  }),
);

interface Iparams {
  report_id: string;
}

export default function ReportItemComponent() {
  const classes = useStyles();
  const history = useHistory();
  const params: Iparams = useParams();
  const report_id = params.report_id;

  const [isLoading, setLoading] = useState(true);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [reportInfo, setReportInfo] = useState<Array<IReportItem>>([]);
  const [reportMeta, setReportMeta] = useState<IReportMeta | null>(null);
  const [promptPercent, setPromptPercent] = useState(0);
  const [snackBar, showSnackBar] = React.useState<boolean>(false);
  const [AIsurveyTheme, setAIsurveyTheme] = useState<string>('');
  const [rightState, setRightState] = React.useState(false);
  const [themeItem, setThemeItem] = React.useState<IWorkspaceTheme>();

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'report item tab viewed', {});
    }
  }, [tracking_info]);

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setRightState(!rightState);
    setThemeItem(undefined);
  };

  const getReportInfoById = async (should_refresh = false) => {
    if (!report_id) {
      return;
    }

    const results = await getReportById(report_id, should_refresh);
    if (results && results.report_info) {
      const { report_info, ...otherProps } = results;

      setReportInfo(report_info);
      setReportMeta({ ...reportInitialData, ...otherProps });
    }
  };

  useEffect(() => {
    getReportInfoById();
  }, []);

  const handleRefreshInfo = async () => {
    if (!report_id) {
      return;
    }

    showSnackBar(true);
    getReportInfoById(true);

    const timer_id = setInterval(async () => {
      const results = await getReportById(report_id, false);
      if (results && results.report_info) {
        const { updated_at } = results;
        const diff_secs = moment(new Date()).diff(moment(new Date(updated_at)), 'seconds');
        if (diff_secs <= 45) {
          clearInterval(timer_id);
          showSnackBar(false);
        }
      }
    }, 20 * 1000);
  };

  const handleSurveyFromPrompt = async (objective: string) => {
    const newDraft = await createSurvey({ research_type: 'survey' });

    let curr_percent = 5;
    const timer_id = setInterval(() => {
      curr_percent += 11;
      setPromptPercent(curr_percent);
      if (curr_percent >= 93) {
        clearInterval(timer_id);
      }
    }, 1000);

    const survey_result = await createSurveyFromPrompt(
      {
        objective,
        hypothesis: '',
        question_count: 5,
        prompt_type: 'generative',
      },
      newDraft.survey_id,
    );

    if (survey_result) {
      history.push(`/survey/${newDraft.survey_id}/editor`);
    }
  };

  let insightDateRange = reportMeta && reportMeta.date_range ? reportMeta.date_range : null;

  if (insightDateRange && insightDateRange['date_range'] && insightDateRange['date_range'].type === 'days') {
    const { time_to, time_from } = dateRangeQuery(insightDateRange['date_range']);

    insightDateRange = { ...insightDateRange['date_range'], time_to, time_from };
  }

  if (!isLoading || !reportMeta) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    return (
      <div>
        {reportMeta && (
          <ReportFilter
            open={openFilter}
            toggleModal={() => {
              setOpenFilter(!openFilter);
            }}
            reportMeta={reportMeta}
            setReportMeta={setReportMeta}
            getReportInfoById={getReportInfoById}
            reportId={report_id}
          />
        )}

        {insightDateRange && themeItem && rightState && (
          <InsightTopicDrawer
            toggleDrawer={toggleDrawer}
            themeItem={themeItem}
            dateRange={insightDateRange}
            rightState={rightState}
            source={''}
          />
        )}

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackBar}
          onClose={() => {
            showSnackBar(false);
          }}
          message="Hold on, we are working on gathering the insights"
          key={'top' + 'center'}
          action={
            <React.Fragment>
              <CircularProgress className={classes.stickyLoading} size={16} />
            </React.Fragment>
          }
        />
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={5}>
          <div>
            <Typography variant="h2" mb={1}>
              {reportMeta.report_name}
            </Typography>

            <Typography variant={'subtitle2'} fontSize={14} mb={2.5} style={{ maxWidth: '50vw' }}>
              {reportMeta.report_description}
            </Typography>

            <Box display={'flex'}>
              {/* <Typography variant="subtitle1" mr={2}>
                <span style={{ fontWeight: 'bold' }}>202</span> reports
              </Typography>
              <Typography variant="subtitle1">
                <span style={{ fontWeight: 'bold' }}>90%</span> negative sentiment
              </Typography> */}
            </Box>
          </div>
          <div>
            <Box display={'flex'} alignItems={'flex-end'} flexDirection={'row'}>
              <Box display={'flex'}>
                <Typography variant="subtitle1" mr={1} color={'gray'} mt={0.25}>
                  {insightDateRange ? getDateRangeValue(insightDateRange) : ''}
                </Typography>
                <Button
                  className={classes.filterButton}
                  style={{
                    color: 'black',
                    marginLeft: 10,
                  }}
                  onClick={() => {
                    handleRefreshInfo();
                  }}
                >
                  <RefreshIcon />
                  <Typography variant="subtitle1" fontWeight={700} ml={1}>
                    Refresh
                  </Typography>
                </Button>
              </Box>
              <Button
                className={classes.filterButton}
                style={{
                  color: 'black',
                  marginLeft: 10,
                }}
                onClick={() => {
                  setOpenFilter(true);
                }}
              >
                <Box display={'flex'} alignItems={'center'}>
                  <img src={filterImg} alt="" />
                  <Typography variant="subtitle1" fontWeight={700} ml={1}>
                    Filter
                  </Typography>
                </Box>
              </Button>
              {/*<Button
                className={classes.filterButton}
                aria-controls="customized-menu"
                endIcon={<ExpandMoreIcon />}
                onClick={() => {}}
                style={{
                  color: 'black',
                }}
              >
                Share & export
              </Button>*/}
            </Box>
          </div>
        </Box>
        {/* <Typography variant="subtitle1" mt={5} mb={3}>
          <span style={{ fontWeight: 'bold' }}>392</span> Identified from
          <span style={{ fontWeight: 'bold' }}> 97349</span> user inputs negative sentiment
        </Typography> */}

        {reportInfo &&
          isArray(reportInfo) &&
          reportInfo.map((item, idx) => (
            <>
              <Grid container className={classes.topicItem} mb={3}>
                <Typography style={{ position: 'absolute', marginLeft: -60, marginTop: 3 }}>#{+idx + 1}</Typography>
                <Grid item lg={6}>
                  <Box>
                    <Box display={'flex'}>
                      <img src={intentImgFormat(item.intent)} width={18} style={{ marginRight: 5, marginTop: -3 }} />
                      <Typography variant={'body1'} fontWeight={'bold'} fontSize={18} mb={1}>
                        {item.theme_name}
                      </Typography>
                    </Box>
                    <Typography variant={'subtitle2'} fontSize={14} mb={1}>
                      {item.summary}
                    </Typography>
                    <Typography variant={'subtitle2'} fontSize={16} mb={1} mt={2}>
                      What your users are saying?
                    </Typography>
                    {item.representative_docs &&
                      item.representative_docs.split('##').map(doc => (
                        <Box display={'flex'} ml={0.5}>
                          <img src={userImgFormat('', '').userImg} width={12} />
                          <Typography variant={'subtitle2'} className={classes.representativeText} fontSize={14} ml={1}>
                            {doc}
                          </Typography>
                        </Box>
                      ))}

                    <Typography
                      variant={'subtitle2'}
                      fontWeight={'bold'}
                      mt={0.5}
                      mb={2}
                      onClick={() => {
                        setRightState(true);
                        setThemeItem({
                          action: '',
                          category: '',
                          sentiment: item.sentiment,
                          theme_id: +item.theme_id,
                          theme_name: item.theme_name,
                          theme_text: '',
                          count: 0,
                          tags: [],
                        });
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      Show more
                    </Typography>
                    <Box
                      className={classes.aiSurveyBox}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <div>
                        <Typography
                          className={classes.violetText}
                          variant={'subtitle1'}
                          fontWeight={'bold'}
                          mt={0.5}
                          mb={1}
                        >
                          Get more validation on this topic
                        </Typography>
                        {item.assumptions.split('##').map((assumption, idx) => (
                          <Typography className={classes.violetText} variant={'subtitle2'} mt={1} fontSize={14}>
                            {`${+idx + 1}. `} {assumption}
                          </Typography>
                        ))}
                        <Box>
                          <Box
                            className={classes.aiSurveyBtn}
                            display="flex"
                            alignItems="center"
                            ml={'auto'}
                            onClick={() => {
                              handleSurveyFromPrompt(item.assumptions);
                              setAIsurveyTheme(item.theme_name);
                            }}
                            mt={2}
                          >
                            <img src={aiStarImg} alt="" />
                            <Typography variant="subtitle1" color="info.contrastText" pl={0.5}>
                              Generate AI-survey
                            </Typography>
                          </Box>
                          {AIsurveyTheme && item.theme_name === AIsurveyTheme && (
                            <Box
                              display="flex"
                              alignItems="flex-end"
                              flexDirection={'column'}
                              justifyContent={'flex-end'}
                              mt={1}
                            >
                              <div style={{ width: '200px' }}>
                                <LinearProgress
                                  variant="determinate"
                                  value={promptPercent}
                                  className={classes.progress}
                                />
                              </div>
                            </Box>
                          )}
                        </Box>
                      </div>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={5} pl={3}>
                  <Typography variant={'body1'} mb={1}>
                    Why it matters
                  </Typography>
                  <Box display={'flex'} justifyContent={'space-between'} width={'95%'}>
                    <div>
                      <Typography variant={'body2'} fontWeight={'bold'}>
                        <CountUp end={item.reports} duration={3} />
                      </Typography>
                      <Typography variant="subtitle2" fontSize={16} color={'gray'}>
                        Reports
                      </Typography>
                    </div>

                    <div>
                      <Typography variant={'body2'} fontWeight={'bold'}>
                        <CountUp end={item.average_weekly_reports} duration={3} />
                      </Typography>
                      <Typography variant="subtitle2" fontSize={16} color={'gray'}>
                        Weekly Average
                      </Typography>
                    </div>

                    <div>
                      <Typography variant={'body2'} fontWeight={'bold'}>
                        {capitalizeFirstLetter(item.intent)}
                      </Typography>
                      <Typography variant="subtitle2" fontSize={16} color={'gray'}>
                        Intent
                      </Typography>
                    </div>

                    <div>
                      <Typography variant={'body2'} fontWeight={'bold'}>
                        {capitalizeFirstLetter(item.sentiment)} ({item['sentiment%']}%)
                        {item && item.sentiment ? sentimentObj[item.sentiment.toLowerCase()] : ''}
                      </Typography>
                      <Typography variant="subtitle2" fontSize={16} color={'gray'}>
                        Sentiment (%)
                      </Typography>
                    </div>
                  </Box>
                  <Typography variant={'body1'} mt={5}>
                    Occurance
                  </Typography>

                  <Box width={'96%'} mt={2} mr={1}>
                    <DailyChartComponent
                      x_axis={item.chart && item.chart.x_axis ? item.chart.x_axis : []}
                      data_groups={item.chart && item.chart.data_groups ? item.chart.data_groups : []}
                      question_type={'reports'}
                      chartHeight={150}
                      is_dashboard={true}
                    />
                  </Box>
                  <Box>
                    <Typography variant={'body1'} mt={3}>
                      Trends & Anomalies
                    </Typography>
                    {item.trends_anomalies.split('##').map((trend, idx) => (
                      <Typography variant={'subtitle2'} mt={1} fontSize={14}>
                        {`${+idx + 1}. `} {trend}
                      </Typography>
                    ))}{' '}
                  </Box>
                </Grid>
              </Grid>
            </>
          ))}
      </div>
    );
  }
}
