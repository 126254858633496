import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Box,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  CircularProgress,
  Button,
  Switch,
} from '@mui/material';
import { useStyles } from './EditorStyle';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import TranslateDialog from './TranslateModal';
import QuestionComponent from './QuestionComponent';
import ConfigureComponent from './ConfigureComponent';
import AIGenerateComponent from './AIGenerateComponent';

import HeaderComponent from './Header';
import PrototypePreview from './PrototypePreview';

import { allLanguages } from '../../static/languageData';

import { ISurvey } from '../../types/survey';
import { fetchSurveyById, editSurvey, editSurveyStatus } from '../../services/surveyService';

import warningImg from '../../images/warning-icon.svg';
import { ReactComponent as LanguageIcon } from '../../images/editor/language-icon.svg';
import { ReactComponent as CrossIcon } from '../../images/editor/cross-icon.svg';
import defaultUploadImg from '../../images/editor/default-upload-img.svg';

import { checkSurveyForPublish } from '../../utils/questionUtil';
import AlertUtil from '../../utils/AlertUtil';
import { GreenCheckbox } from '../../utils/CheckBoxUtil';
import { amplitudeEvent } from '../../utils/amplitudeUtil';

import { SurveyDemo } from './SurveyDemo';
import { RootState } from '../../store';
import QuizComponent from './QuizComponent';
import { capitalizeFirstLetter } from '../../utils/stringUtil';

interface Iparams {
  survey_id: string;
}

function SurveyEditorComponent() {
  const classes = useStyles();

  const history = useHistory();
  const params: Iparams = useParams();
  const { survey_id } = params;
  const [survey, setSurvey] = useState<ISurvey>();
  const [allowSaveClick, setAllowSaveClick] = useState(false);
  const [openTranslateToggle, toggleTranslateModal] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState('en');
  const [errors, setErrors] = useState('');
  const [isLoading, setLoading] = useState(true);

  const [activeTab, setActiveTab] = useState(0);

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  const fetchSurvey = useCallback(
    async (language_code?: string) => {
      const lang_code = language_code ? language_code : activeLanguage;
      const surveyData: ISurvey = await fetchSurveyById(survey_id, lang_code);
      if (surveyData) {
        if (surveyData.status === 'live') {
          history.push(`/survey/${survey_id}`);
        }
        setSurvey(surveyData);
      }
    },
    [survey_id],
  );

  useEffect(() => {
    if (!survey_id) {
      history.goBack();
      return;
    }
    fetchSurvey();
    setLoading(false);
  }, [history, survey_id, fetchSurvey]);

  const updateChanges = (survey: ISurvey) => {
    setSurvey(survey);
  };

  const updateDraftChanges = async (show_alert = false, check_publish = true, check_settings = true) => {
    if (!survey) {
      return false;
    }

    if (check_publish) {
      const canPublish = checkSurveyForPublish(survey, false, check_settings);
      const { success, message } = canPublish;

      if (!success) {
        if (message) {
          setErrors(message);
          setTimeout(() => setErrors(''), 5000);
        }
        return false;
      }
    }

    const { questions, survey_id, ...surveyDetails } = survey;

    setErrors('');

    const edit_results = await editSurvey({ questions, survey_id, ...surveyDetails }, survey_id, activeLanguage);
    if (edit_results) {
      await fetchSurvey(activeLanguage);
      if (show_alert) {
        AlertUtil.fire({
          icon: 'success',
          title: 'Saved successfully',
        });
        if (tracking_info) {
          amplitudeEvent(tracking_info, 'editor save clicked', {
            'survey id': survey_id,
            'survey name': survey.survey_name,
          });
        }
      }
      return true;
    }

    return false;
  };

  const handleSaveChanges = async () => {
    //how_alert, check_publish, check_settings
    const check_survey = await updateDraftChanges(false, true, false);

    if (!check_survey) {
      return;
    }

    setAllowSaveClick(true);

    const verified = await editSurveyStatus(survey_id, 'verify');

    if (verified && verified.data) {
      setAllowSaveClick(false);
      if (verified.data.success) {
        setErrors('');
        AlertUtil.fire({
          icon: 'success',
          title: 'Saved successfully',
        });
        setActiveTab(1);
      } else {
        setErrors(verified.data.errorText);
        setTimeout(() => setErrors(''), 5000);
      }
    }
  };

  const publishDraft = async () => {
    if (!survey) {
      return;
    }

    await updateDraftChanges(false, false);

    const canPublish = checkSurveyForPublish(survey, true);

    const { success, message } = canPublish;

    if (!success) {
      if (message) {
        setErrors(message);
        setTimeout(() => setErrors(''), 5000);
      }
      return false;
    }

    const publish = await editSurveyStatus(survey_id, 'publish');

    if (publish && publish.data) {
      if (publish.data.success) {
        const research_text = survey && survey.research_type ? capitalizeFirstLetter(survey.research_type) : 'Survey';
        setErrors('');
        AlertUtil.fire({
          icon: 'success',
          title: `${research_text} published`,
        });

        history.push(`/survey/${survey_id}`);
      } else if (publish.data.errorText) {
        if (publish.data.errorText.includes('Free')) {
          AlertUtil.fire({
            icon: 'error',
            title: publish.data.errorText,
          });
        } else {
          setErrors(publish.data.errorText);
          setTimeout(() => setErrors(''), 5000);
        }
      } else {
        setErrors('Unexpected error. Please try again');
        setTimeout(() => setErrors(''), 5000);
      }
    }
  };

  //handle all question field changes by updating survey state and initialzing a debounce task
  const editQuestionHandler = async (fieldName: string, fieldValue: string, q_id: string) => {
    if (!survey) {
      return;
    }
    const { questions } = survey;

    for (const i in questions) {
      if (questions[i].question_id === q_id) {
        if (
          fieldName === 'question_desc' ||
          fieldName === 'question_text' ||
          fieldName === 'cta_link' ||
          fieldName === 'cta_text' ||
          fieldName === 'prototype_link' ||
          fieldName === 'question_variant' ||
          fieldName === 'placeholder' ||
          fieldName === 'quiz_explanation'
        ) {
          questions[i][fieldName] = fieldValue;
        } else if (fieldName === 'is_mandatory') {
          questions[i][fieldName] = Boolean(fieldValue);
        } else if (fieldName === 'show_as_dropdown') {
          questions[i][fieldName] = fieldValue === 'dropdown';
        } else if (
          (fieldName === 'left' || fieldName === 'right') &&
          questions[i] &&
          questions[i].properties &&
          questions[i].properties.labels
        ) {
          questions[i].properties.labels[fieldName] = fieldValue;
        } else if (
          fieldName === 'randomize' &&
          questions[i] &&
          questions[i].properties &&
          questions[i].properties.randomize
        ) {
          questions[i].properties[fieldName] = fieldValue;
        } else if (questions[i].validations && fieldName === 'field_type') {
          questions[i].validations.field_type = fieldValue;
        } else if (questions[i].validations && fieldName === 'min_length') {
          questions[i].validations.min_length = +fieldValue;
        } else if (questions[i].validations && fieldName === 'max_length') {
          questions[i].validations.max_length = +fieldValue;
        } else if (questions[i].max_selections && fieldName === 'max_selections') {
          questions[i].max_selections = +fieldValue;
        }
        updateChanges({ ...survey, questions });
      }
    }
  };

  const editMandatoryQuestionHandler = async (fieldName: string, fieldValue: boolean, q_id: string) => {
    if (!survey) {
      return;
    }
    const { questions } = survey;

    for (const i in questions) {
      if (questions[i].question_id === q_id) {
        if (fieldName === 'is_mandatory') {
          questions[i][fieldName] = fieldValue;
        }
        updateChanges({ ...survey, questions });
      }
    }
  };

  type optionItem = { option_text: string; order: number; _id?: string | undefined; has_text_input: boolean };

  /**
   * handle actions(add,delete,edit) on options
   * @param q_id question_id
   * @param action string value enum(add, delete,edit)
   * @param idx index of the option (used in delete & edit actions)
   * @param option_text option text to be edited ( used in edit action)
   * @returns
   */
  const editQuestionChoice = async (q_id: string, action: string, idx: number, option_text?: string) => {
    if (!survey) {
      return;
    }
    const { questions } = survey;

    for (const i in questions) {
      const { question_id, rule_sets } = questions[i];
      if (question_id === q_id && questions[i] && questions[i].properties && questions[i].properties.options) {
        const options = questions[i].properties.options;
        if (action === 'add') {
          if (idx === -1) {
            questions[i].properties.options = [
              ...options,
              { option_text: 'Others', order: options.length + 1, has_text_input: true },
            ];
            if (
              questions[i].properties &&
              questions[i].properties.randomize &&
              questions[i].properties.randomize === 'randomize'
            ) {
              questions[i].properties.randomize = 'randomize_but_last';
            }
          } else if (options.length >= 0 && options.length < 15) {
            const temp_options = options;
            const input_option_idx = questions[i].properties.options.findIndex(o => o.has_text_input);

            //ordering others option
            if (input_option_idx >= 0) {
              const others_item = temp_options.splice(input_option_idx, 1);
              questions[i].properties.options = [
                ...temp_options,
                { option_text: '', order: options.length, has_text_input: false },
                { ...others_item[0], order: options.length + 1 },
              ];
            } else {
              questions[i].properties.options = [
                ...options,
                { option_text: '', order: options.length + 1, has_text_input: false },
              ];
            }
          } else {
            AlertUtil.fire({
              icon: 'error',
              title: 'More than 15 choices cannot be added',
            });
            return;
          }
        } else if (action === 'delete') {
          const deleted_item = options.splice(idx, 1);

          const modified_options: Array<optionItem> = [];
          for (let i = 0; options.length > i; i++) {
            if (options[i])
              modified_options.push({
                option_text: options[i].option_text,
                order: i + 1,
                _id: options[i]._id ? options[i]._id : undefined,
                has_text_input: options[i].has_text_input ? true : false,
              });
          }
          questions[i].properties.options = modified_options;

          for (const j in rule_sets) {
            if (rule_sets[j].options && deleted_item.length > 0) {
              rule_sets[j].options = rule_sets[j].options?.filter(r => r !== deleted_item[0].option_text);
            }
          }
        } else if (action === 'edit') {
          if (!option_text) option_text = '';
          options[idx].option_text = option_text;
        } else if (action === 'edit_score') {
          if (!option_text) {
            options[idx].quiz_score = 0;
          } else {
            options[idx].quiz_score = +option_text;
          }
        } else if (action === 'edit_correct_option') {
          if (!option_text) option_text = '';
          options[idx].is_correct_option = option_text === 'true';
        }

        updateChanges({ ...survey, questions });
      }
    }
  };

  const editSurveyDetails = async (fieldName: string, fieldValue: string | boolean) => {
    if (!survey) {
      return null;
    }

    const { status } = survey;

    if (status === 'archived' || status === 'completed') {
      return;
    }

    const fieldNameArr = [
      'survey_name',
      'survey_end_date',
      'survey_response_limit',
      'survey_retake_days',
      'platform',
      'show_thanks_card',
      'thanks_card_title',
      'thanks_card_desc',
      'show_intro',
      'intro_card_title',
      'intro_card_cta',
      'user_cohort',
    ];

    if (fieldName === 'platform') {
      updateChanges({ ...survey, [fieldName]: fieldValue.toString(), trigger_with_events: [] });
    } else if (fieldValue !== null && fieldNameArr.includes(fieldName)) {
      updateChanges({ ...survey, [fieldName]: fieldValue });
    }
  };

  const editSurveyPrompt = (fieldName: string, fieldValue: string | number) => {
    if (!survey) {
      return;
    }

    const { survey_prompt } = survey;

    survey_prompt[fieldName] = fieldValue;
    updateChanges({ ...survey, survey_prompt });
  };

  const editLanguageHandler = async (action: string, fieldValue: string) => {
    if (!survey) {
      return;
    }

    let { languages } = survey;

    if (action === 'add' && !languages.includes(fieldValue)) {
      if (languages.length >= 0 && languages.length <= 10) {
        languages.push(fieldValue);
        updateChanges({ ...survey, languages });
      } else {
        AlertUtil.fire({
          icon: 'error',
          title: 'More than 10 languages cannot be added',
        });
        return;
      }
    } else if (action === 'delete') {
      languages = languages.filter(l => l !== fieldValue);
      updateChanges({ ...survey, languages });
      const edit_changes = await editSurvey({ ...survey, languages }, survey_id, activeLanguage);
      if (edit_changes) {
        setErrors('');
        await fetchSurvey('en');
        setActiveLanguage('en');
      }
    }
  };

  if (!survey || isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    const {
      survey_name,
      questions,
      show_thanks_card,
      thanks_card_title,
      thanks_card_desc,
      languages,
      survey_id,
      status,
      default_language,
      research_type,
    } = survey;

    survey['activeLanguage'] = activeLanguage;

    let can_edit = true;
    let can_save_changes = false;
    let can_display_prompt = false;

    if (
      (activeLanguage === 'en' && survey.survey_prompt && survey.survey_prompt.objective) ||
      (activeLanguage === 'en' && (status === 'created' || status === 'draft'))
    ) {
      can_display_prompt = true;
    }

    if (research_type === 'quiz') {
      can_display_prompt = false;
    }

    if (status === 'live' || status === 'archived' || status === 'completed' || activeLanguage !== 'en') {
      can_edit = false;
    }

    if (status === 'created' || status === 'draft' || status === 'paused') {
      can_save_changes = true;
    }

    const toggleLanguage = async (language_code: string) => {
      if (!survey) {
        return;
      }

      const { status } = survey;

      let can_toogle = false;

      if (status === 'archived' || status === 'completed') {
        await fetchSurvey(language_code);
        setActiveLanguage(language_code);
        return;
      }

      if (activeLanguage === 'en') {
        await updateDraftChanges(false, false);
        can_toogle = true;
      } else {
        const edit_changes = await editSurvey(survey, survey_id, activeLanguage);
        if (edit_changes) {
          can_toogle = true;
        }
      }

      if (can_toogle) {
        setErrors('');
        await fetchSurvey(language_code);
        setActiveLanguage(language_code);
      }
    };

    return (
      <>
        {openTranslateToggle && (
          <TranslateDialog
            toggleModal={toggleTranslateModal}
            open={openTranslateToggle}
            activeLanguage={activeLanguage}
            fetchSurvey={fetchSurvey}
            survey_id={survey_id}
          />
        )}
        <HeaderComponent
          survey={survey}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          handleSaveChanges={handleSaveChanges}
          allowSaveClick={allowSaveClick}
          updateDraftChanges={updateDraftChanges}
          publishDraft={publishDraft}
          researchType={research_type}
        />

        <Grid container className={classes.root}>
          {activeTab === 0 ? (
            <Grid item lg={6}>
              <TextField
                fullWidth
                autoFocus
                variant="outlined"
                autoComplete="off"
                value={survey_name}
                name="survey_name"
                className={classes.surveyNameInput}
                placeholder="Enter survey name"
                onChange={e => editSurveyDetails(e.target.name, e.target.value)}
              />

              {can_display_prompt && (
                <AIGenerateComponent survey={survey} editSurveyPrompt={editSurveyPrompt} fetchSurvey={fetchSurvey} />
              )}

              {research_type !== 'quiz' && (
                <Box display={'flex'} alignItems={'center'} pt={1}>
                  <Select
                    variant="outlined"
                    displayEmpty
                    className={classes.editorSelectField}
                    renderValue={() => <LanguageIcon />}
                  >
                    <Box className={classes.languageMenu}>
                      <MenuItem disabled>
                        <Typography variant={'subtitle2'}>Translate survey to :</Typography>
                      </MenuItem>
                      <Box>
                        {Object.keys(allLanguages).map((item, idx) => (
                          <MenuItem value={item} key={idx} onClick={e => editLanguageHandler('add', item)}>
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem' }} />}
                              checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
                              classes={{
                                root: classes.check,
                                checked: classes.checked,
                              }}
                              checked={languages.includes(item)}
                            />
                            <Typography color={languages.includes(item) ? 'primary.contrastText' : ''} pl={1.2}>
                              {allLanguages[item]}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Box>
                    </Box>
                  </Select>

                  <Typography
                    p={2}
                    pt={0.8}
                    pb={0.9}
                    className={activeLanguage === 'en' ? classes.activeLanguageText : classes.languageText}
                    onClick={() => toggleLanguage('en')}
                  >
                    English
                  </Typography>
                  {languages?.map((item, idx) => (
                    <Box
                      display="flex"
                      alignItems="center"
                      p={2}
                      pt={0.8}
                      pb={0.9}
                      style={{ backgroundColor: '#fff' }}
                      className={activeLanguage === item ? classes.activeLanguageText : classes.languageText}
                      key={idx}
                    >
                      <Typography pr={2.1} onClick={() => toggleLanguage(item)}>
                        {allLanguages[item]}
                      </Typography>
                      <CrossIcon style={{ paddingTop: '0.2rem' }} onClick={e => editLanguageHandler('delete', item)} />
                    </Box>
                  ))}
                </Box>
              )}

              <div className={classes.questionContainer}>
                {errors && (
                  <Box className={classes.errorBox} p={2} display="flex" alignItems="center">
                    <img src={warningImg} alt="" />
                    <Typography style={{ marginLeft: 5 }}>{errors}</Typography>
                  </Box>
                )}

                {(status === 'paused' || status === 'live') && (
                  <Box className={classes.errorBox} p={2} display="flex" alignItems="center">
                    <img src={warningImg} alt="" />
                    <Typography variant="subtitle1" lineHeight={1.25} style={{ marginLeft: 5 }}>
                      Editing questions on a live active survey can seriously impact the insights/results; we recommend
                      editing of questions only to fix errors.
                    </Typography>
                  </Box>
                )}
                {activeLanguage !== 'en' && can_save_changes && (
                  <Box
                    className={classes.notificationBox}
                    py={1}
                    px={2.1}
                    display="flex"
                    alignItems={'center'}
                    justifyContent="space-between"
                  >
                    <Typography variant={'subtitle1'}>
                      Press update to translate changes from English survey to {allLanguages[activeLanguage]} &#8594;
                    </Typography>

                    <Typography
                      variant={'subtitle1'}
                      px={2.5}
                      py={0.5}
                      className={classes.updateText}
                      onClick={() => toggleTranslateModal(true)}
                    >
                      Update translation
                    </Typography>
                  </Box>
                )}

                {default_language !== 'en' && !languages.includes(default_language) && research_type !== 'quiz' && (
                  <Box
                    className={classes.warningBox}
                    py={1}
                    px={2.1}
                    display="flex"
                    alignItems={'center'}
                    justifyContent="space-between"
                  >
                    <Typography variant={'subtitle1'}>
                      Add {allLanguages[default_language]} to the survey, as it is the default language for the users.
                    </Typography>
                  </Box>
                )}

                {research_type === 'quiz' ? (
                  <QuizComponent
                    survey={survey}
                    updateChanges={updateChanges}
                    survey_id={survey_id}
                    fetchSurvey={fetchSurvey}
                    can_edit={can_edit}
                    can_save_changes={can_save_changes}
                    editQuestionHandler={editQuestionHandler}
                    editMandatoryQuestionHandler={editMandatoryQuestionHandler}
                    editQuestionChoice={editQuestionChoice}
                    activeLanguage={activeLanguage}
                    updateDraftChanges={updateDraftChanges}
                  />
                ) : (
                  <QuestionComponent
                    survey={survey}
                    updateChanges={updateChanges}
                    survey_id={survey_id}
                    fetchSurvey={fetchSurvey}
                    can_edit={can_edit}
                    can_save_changes={can_save_changes}
                    editQuestionHandler={editQuestionHandler}
                    editMandatoryQuestionHandler={editMandatoryQuestionHandler}
                    editQuestionChoice={editQuestionChoice}
                    activeLanguage={activeLanguage}
                    updateDraftChanges={updateDraftChanges}
                  />
                )}

                {questions &&
                  questions.length > 0 &&
                  (show_thanks_card || activeLanguage === 'en') &&
                  research_type !== 'quiz' && (
                    <div>
                      <div className={classes.thanksCardBorder}></div>
                      <Box mt={5} className={classes.thankYouBox} alignItems="center">
                        <Box
                          className={classes.thanksTopBox}
                          borderBottom={show_thanks_card ? 1 : 0}
                          borderColor={'divider'}
                        >
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            style={{ cursor: 'pointer', maxWidth: 'fit-content' }}
                            onClick={e => editSurveyDetails('show_thanks_card', !show_thanks_card)}
                          >
                            {activeLanguage === 'en' && <GreenCheckbox checked={show_thanks_card} />}
                            <Typography pl={1.25} color={show_thanks_card ? 'success.main' : 'text.secondary'}>
                              Show thank you card on completion
                            </Typography>
                          </Box>
                        </Box>

                        {show_thanks_card && (
                          <Box px={4.5} py={5}>
                            <Typography variant="subtitle1" className={classes.inputLabel}>
                              Headline
                            </Typography>
                            <TextField
                              fullWidth
                              className={classes.thanksTextField}
                              variant="outlined"
                              sx={{
                                '& .MuiOutlinedInput-input': {
                                  fontWeight: 700,
                                },
                              }}
                              value={thanks_card_title}
                              name="thanks_card_title"
                              onChange={e => editSurveyDetails(e.target.name, e.target.value)}
                            />
                            <Typography variant="subtitle1" className={classes.inputLabel}>
                              Subtext
                            </Typography>
                            <TextField
                              fullWidth
                              className={classes.thanksTextField}
                              variant="outlined"
                              value={thanks_card_desc}
                              name="thanks_card_desc"
                              onChange={e => editSurveyDetails(e.target.name, e.target.value)}
                            />
                          </Box>
                        )}
                      </Box>
                    </div>
                  )}
              </div>
            </Grid>
          ) : (
            <ConfigureComponent
              survey={survey}
              fetchSurvey={fetchSurvey}
              updateChanges={updateChanges}
              survey_id={survey_id}
              can_edit={can_edit}
              can_save_changes={can_save_changes}
              errors={errors}
            />
          )}

          <Grid item lg={4}>
            {survey &&
              (research_type === 'prototype' ? (
                <PrototypePreview surveyInfo={survey} source="survey editor" updateDraftChanges={updateDraftChanges} />
              ) : (
                <SurveyDemo surveyInfo={survey} source="survey editor" />
              ))}
          </Grid>
        </Grid>
      </>
    );
  }
}

export default SurveyEditorComponent;
