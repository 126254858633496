import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

import { useSelector } from 'react-redux';
import { Box, Typography, Grid, Paper, CircularProgress } from '@mui/material';

import { amplitudeEvent } from '../../../utils/amplitudeUtil';
import { RootState } from '../../../store';

import { useStyles } from './Report.style';
import { LinearProgressForReport } from '../../../utils/ReportProgressUtil';
import { getReportById } from '../../../services/insightService';
import { IReportFeq } from './report';
import { DoughnutComponent } from '../../spotlight/charts/DoughChartComponent';
import { DailyChartComponent } from '../../spotlight/charts/DailyChartComponent';
import InsightTopicDrawer from '../InsightTopicDrawer';
import { IWorkspaceTheme } from '../../../types/insight';
import { reportDayCount } from './constants';
import { dateRangeQuery } from '../../../utils/DateUtil';
import { getDateRangeValue } from '../../spotlight/DateFilter';
import { isArray } from 'lodash';

interface Iparams {
  report_id: string;
}

const FeqReportHomeComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const params: Iparams = useParams();

  const report_id = params.report_id;

  const [reportFeqItem, setReportFeqItem] = useState<IReportFeq | null>(null);
  const [rightState, setRightState] = React.useState(false);
  const [themeItem, setThemeItem] = React.useState<IWorkspaceTheme>();

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'report item page viewed', {});
      if (tracking_info && !tracking_info.show_discovery) {
        window.location.href = '/';
      }
    }
  }, [tracking_info]);

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setRightState(!rightState);
    setThemeItem(undefined);
  };

  const getReportInfoById = async () => {
    if (!report_id) {
      return;
    }

    const results = await getReportById(report_id, false);
    if (results) {
      setReportFeqItem(results);
    }
  };

  useEffect(() => {
    getReportInfoById();
  }, []);

  const DoughnutItem = (
    title: string,
    data: Array<{
      name: string;
      count: number;
      count_perc?: any;
    }>,
  ) => {
    return (
      <Grid item lg={6} key={0}>
        <Paper className={classes.dashboardPaper} style={{ cursor: 'default', height: '350px' }}>
          <Box display="flex" flexDirection={'column'}>
            <Box>
              <Typography variant={'subtitle1'} fontWeight={'bold'} fontSize={18}>
                {title}
              </Typography>
            </Box>

            <Box mt={0.5}>
              <DoughnutComponent chartHeight={70} data={data} />
            </Box>
          </Box>
        </Paper>
      </Grid>
    );
  };

  const DailyChartItem = (
    title: string,
    trend_chart: { x_axis: Array<string>; data_groups: Array<{ label: string; values: Array<number> }> },
  ) => {
    return (
      <Grid item lg={6} key={0}>
        <Paper className={classes.dashboardPaper} style={{ cursor: 'default', height: '350px' }}>
          <Box display="flex" flexDirection={'column'}>
            <Box>
              <Typography variant={'subtitle1'} fontWeight={'bold'} fontSize={18}>
                {title}
              </Typography>
            </Box>

            <Box mt={0.5}>
              <DailyChartComponent
                x_axis={trend_chart.x_axis}
                data_groups={trend_chart.data_groups}
                question_type={'input_feedback'}
                chartHeight={160}
              />
            </Box>
          </Box>
        </Paper>
      </Grid>
    );
  };

  const IntentGridItem = (
    title: string,
    themes: Array<{
      theme_id: string;
      theme_name: string;
      count: number;
      count_perc: string;
      perc: number;
    }>,
  ) => {
    return (
      <Grid item lg={6} key={0}>
        <Paper className={classes.dashboardPaper} style={{ cursor: 'default', height: '350px', overflowX: 'scroll' }}>
          <Box display="flex" flexDirection={'column'}>
            <Box>
              <Typography variant={'subtitle1'} fontWeight={'bold'} fontSize={18} mb={2}>
                {title}
              </Typography>
            </Box>

            <Box mb={1.5}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant={'subtitle2'} color={'gray'}>
                  Topic
                </Typography>
                {title === 'Whats New' && (
                  <Typography variant={'subtitle2'} color={'gray'}>
                    Change %
                  </Typography>
                )}
              </Box>

              {themes &&
                themes.length > 0 &&
                themes.map(theme => (
                  <LinearProgressForReport
                    count={theme.count}
                    value={theme.perc}
                    label={theme.theme_name}
                    change_perc={theme.count_perc === 'Infinity' ? 0 : +theme.count_perc}
                    onClickHandler={() => {
                      setRightState(true);
                      setThemeItem({
                        action: '',
                        category: '',
                        sentiment: '',
                        theme_id: +theme.theme_id,
                        theme_name: theme.theme_name,
                        theme_text: '',
                        count: 0,
                        tags: [],
                      });
                    }}
                  />
                ))}
            </Box>
          </Box>
        </Paper>
      </Grid>
    );
  };

  if (!reportFeqItem) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    const {
      sources = [],
      intent = [],
      sentiment = [],
      praise_themes = [],
      improvement_themes = [],
      complaint_themes = [],
      whats_new = [],
      report_name,
      report_description,
      date_range,
      trend_chart = { x_axis: [], data_groups: [] },
      anomaly_chart = { x_axis: [], data_groups: [] },
    } = reportFeqItem;

    const { time_to, time_from } = dateRangeQuery(date_range);

    const insightFeqDateRange = {
      type: 'days',
      days_count: reportDayCount[report_name],
      time_to,
      time_from,
    };

    const EmptyStateInfo = (title: string, text: string) => {
      return (
        <Grid item lg={6} key={0}>
          <Paper className={classes.dashboardPaper} style={{ cursor: 'default', height: '350px', overflowX: 'scroll' }}>
            <Box display="flex" flexDirection={'column'} alignItems={'center'}>
              <Box alignSelf={'flex-start'}>
                <Typography variant={'subtitle1'} fontWeight={'bold'} fontSize={18} mb={2}>
                  {title}
                </Typography>
              </Box>
              <Typography mt={12} className={classes.violetText} width={'300px'} textAlign={'center'}>
                {text}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      );
    };

    return (
      <div>
        {date_range && themeItem && rightState && (
          <InsightTopicDrawer
            toggleDrawer={toggleDrawer}
            themeItem={themeItem}
            dateRange={insightFeqDateRange}
            rightState={rightState}
            source={''}
          />
        )}
        <Box mb={1}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <div>
              <Typography fontSize={'2.2rem'} fontWeight={700} mt={-0.5}>
                {report_name}
              </Typography>

              <Typography variant={'subtitle2'} fontSize={14} mb={2.5} style={{ maxWidth: '50vw' }}>
                {report_description}
              </Typography>
            </div>

            <Typography variant="subtitle1" mr={1} mt={1} color={'gray'}>
              {insightFeqDateRange ? getDateRangeValue(insightFeqDateRange) : ''}
            </Typography>
          </Box>

          <Grid container spacing={3} alignItems="stretch" mt={2}>
            {/* {trend_chart &&
              trend_chart.data_groups &&
              trend_chart.data_groups.length > 0 &&
              DailyChartItem('Trends', trend_chart)}

            {anomaly_chart &&
              anomaly_chart.data_groups &&
              anomaly_chart.data_groups.length > 0 &&
              DailyChartItem('Anomaly', anomaly_chart)} */}
            {intent && isArray(intent) && intent.length > 0
              ? DoughnutItem('Intent Split', intent)
              : EmptyStateInfo(
                  'Intent Split',
                  ' No intent data in sight yet—your report’s keeping a low profile. Check back later or add more datasources!',
                )}
            {sentiment && isArray(sentiment) && sentiment.length > 0
              ? DoughnutItem('Sentiment Split', sentiment)
              : EmptyStateInfo(
                  'Sentiment Split',
                  ' No sentiment data in sight yet—your report’s keeping a low profile. Check back later or add more datasources!',
                )}
            {sources && isArray(sources) && sources.length > 0
              ? DoughnutItem('Source Split', sources)
              : EmptyStateInfo(
                  'Source Split',
                  ' No source data in sight yet—your report’s keeping a low profile. Check back later or add more datasources!',
                )}
            {praise_themes && isArray(praise_themes) && praise_themes.length > 0
              ? IntentGridItem('Top 5 Praise', praise_themes)
              : EmptyStateInfo(
                  'Top 5 Praise',
                  'A trendless week so far so no new praise themes. Let’s wait it out or add more feedback sources for updates!',
                )}
            {improvement_themes && isArray(improvement_themes) && improvement_themes.length > 0
              ? IntentGridItem('Top 5 Improvement', improvement_themes)
              : EmptyStateInfo(
                  'Top 5 Improvement',
                  'A trendless week so far so no new improvement themes. Let’s wait it out or add more feedback sources for updates!',
                )}
            {complaint_themes && isArray(complaint_themes) && complaint_themes.length > 0
              ? IntentGridItem('Top 5 Complaint', complaint_themes)
              : EmptyStateInfo(
                  'Top 5 Complaint',
                  'A trendless week so far so no new complaint themes. Let’s wait it out or add more feedback sources for updates!',
                )}
            {whats_new && isArray(whats_new) && whats_new.length > 0
              ? IntentGridItem('Whats New', whats_new)
              : EmptyStateInfo('Whats New', 'No new topics to note yet. Check back later or add more data sources.')}
          </Grid>
        </Box>
      </div>
    );
  }
};

export default FeqReportHomeComponent;
