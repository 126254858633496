export const MEMBER_SIGNUP = () => `/organisation/member/signup`;
export const APPSUMO_MEMBER_SIGNUP = () => `/organisation/member/appsumo/signup`;
export const MEMBER_LOGIN = () => `/organisation/member/signin`;
export const CHANGE_PASSWORD = () => `/organisation/member/change-password`;
export const FORGET_PASSWORD = () => `/organisation/member/forget-password`;
export const RESET_PASSWORD = () => `/organisation/member/reset-password`;
export const TOKEN_EMAIL_INFO = (token: string) => `/organisation/member/token/${token}`;
export const ORGANISATION_DETAILS = () => `/organisation`;
export const EDIT_ORG_DETAILS = () => `/organisation/signup`;
export const MEMBER_TRACKING_INFO = () => `/organisation/member/tracking`;
export const VERIFIED_EMAILS = () => `/organisation/verified-emails`;

export const IMAGE_UPLOAD = () => `/common/img`;
export const FONT_UPLOAD = () => `/common/font`;

export const CREATE_TRIGGER = () => `/trigger`;
export const FETCH_TRIGGERS = () => `/trigger`;
export const EDIT_TRIGGER = (id: string) => `/trigger/${id}`;
export const ENABLE_TRIGGER = (id: string) => `/trigger/${id}/enable`;
export const DISABLE_TRIGGER = (id: string) => `/trigger/${id}/disable`;

export const CREATE_WHITELIST_USER = () => `/user/whitelist`;
export const FETCH_WHITELIST_USERS = () => `/user/whitelist`;

export const FETCH_SURVEY_BY_ID = (id: string, language_code: string) => `/survey/draft/${id}/${language_code}`;
export const SYNC_OTHER_LANG_SURVEYS = (id: string, language_code: string) =>
  `/survey/draft/${id}/lang/${language_code}/sync`;

export const CREATE_SURVEY = () => `/survey/draft`;
export const CREATE_SURVEY_FROM_PROMPT = (id: string) => `/survey/draft/${id}/prompt`;
export const CREATE_SURVEY_TEMPLATE = (id: string) => `/survey/draft/${id}`;
export const FETCH_SURVEY_SEQUENCE = (id: string, platform: string, trigger_name: string) =>
  `/survey/draft/${id}/sequence?platform=${platform}&trigger_name=${trigger_name}`;
export const EDIT_SURVEY_SEQUENCE = (id: string) => `/survey/draft/${id}/sequence`;
export const FETCH_SURVEY_COLLECTIONS = () => `/survey/collections/all`;
export const FETCH_TEMPLATES_BY_TAG = (tag: string) => `/survey/templates/${tag}`;
export const FETCH_TEMPLATES_BY_ID = (tag: string, id: string) => `/survey/templates/${tag}/${id}`;
export const FETCH_QUICK_CONTINUOUS_TEMPLATES = () => `/survey/templates/quick-continous-surveys`;

export const FETCH_SURVEY_LIST = (page_count: number, status: string, platform: string, search_str: string) =>
  `/survey/all?page=${page_count}&status=${status}&platform=${platform}&search_str=${search_str}`;
export const EDIT_SURVEY_STATUS = (id: string, status: string) => `/survey/draft/${id}/${status}`;
export const MIGRATE_SURVEY = (s_id: string, w_id: string) => `/survey/draft/${s_id}/migrate/${w_id}`;

export const EDIT_SURVEY = (id: string, language_code: string) => `/survey/draft/${id}/lang/${language_code}`;
export const EDIT_SURVEY_SETTINGS = (id: string) => `/survey/draft/${id}/settings`;
export const FETCH_SURVEY_RESPONSE_GROUPS = (id: string) => `/survey/user/${id}/details/groups`;
export const FETCH_SURVEY_CSV_RESPONSE = (id: string) => `/survey/user/${id}/details/csv`;
export const FETCH_SURVEY_FOLLOWUP_COUNT = (id: string) => `/survey/user/${id}/details/followup`;
export const CREATE_SURVEY_FOLLOWUP_NEW = (id: string) => `/survey/user/${id}/details/followup/new`;
export const EDIT_SURVEY_FOLLOWUP_NEW = (id: string) => `/survey/user/${id}/details/followup`;
export const FETCH_FOLLOWUP_COHORTS_BY_SURVEY_ID = (id: string) => `/cohort/survey/${id}`;

export const FETCH_SURVEY_DETAILS = (id: string) => `/survey/user/${id}/details`;
export const FETCH_SURVEY_VIEWS = (id: string) => `/survey/user/${id}/views`;

export const FETCH_SURVEY_GRAPH_BY_QUESTION_ID = (survey_id: string, question_id: string) =>
  `/survey/user/${survey_id}/details/${question_id}/graph`;

export const ADD_SURVEY_QUESTION = (id: string, type: string) => `/survey/${id}/question/${type}`;
export const DELETE_SURVEY_QUESTION = (id: string) => `/survey/question/${id}/delete`;
export const EDIT_SURVEY_QUESTION = (id: string) => `/survey/question/${id}/edit`;
export const EDIT_SURVEY_QUESTION_TYPE = (id: string, type: string) => `/survey/question/${id}/edit/${type}`;

export const FETCH_SURVEY_FORM = (id: string) => `/survey/${id}/questions`;
export const INSERT_SURVEY_QUESTIONS = (id: string) => `/survey/${id}/questions`;

export const CREATE_WORKSPACE = () => `/workspace`;
export const EDIT_WORKSPACE = () => `/workspace/settings`;
export const EDIT_SURVEY_THEME = () => `/workspace/theme`;
export const FETCH_WORKSPACE_LIST = () => `/workspace/all`;
export const FETCH_WORKSPACE_BY_ID = () => `/workspace`;
export const TOGGLE_WORKSPACE_BY_ID = (id: string) => `/workspace/${id}/toggle`;
export const FETCH_SURVEY_BRANDING = () => `/workspace/colors`;
export const EDIT_BRANDING_THEME = () => `/workspace/colors`;
export const EDIT_BRANDING_LABEL = () => `/workspace/branding`;
export const EDIT_BRANDING_WEB_SETIINGS = () => `/workspace/web-settings`;

export const CREATE_THEME = () => `/survey/theme`;
export const FETCH_THEME_LIST = () => `/survey/theme/list`;
export const FETCH_THEME_BY_ID = (theme_id: string) => `/survey/theme/${theme_id}`;
export const EDIT_THEME_BY_ID = (theme_id: string) => `/survey/theme/${theme_id}`;
export const DELETE_THEME_BY_ID = (theme_id: string) => `/survey/theme/${theme_id}/delete`;

export const FETCH_WORKSPACE_ATTRIBUTES = () => `/workspace/attributes`;
export const FETCH_WORKSPACE_ATTRIBUTE_VALUES = (id: string) => `/workspace/attributes/${id}`;

export const CREATE_COHORT = () => `/cohort`;
export const CREATE_SURVEY_COHORT = (id: string) => `/cohort/survey/${id}`;
export const EDIT_SURVEY_COHORT = (id: string) => `/cohort/survey/${id}`;
export const FETCH_COHORT_LIST = () => `/cohort`;
export const FETCH_ONGOING_COHORTS = () => `/cohort/ongoing`;
export const FETCH_COHORT_BY_ID = (id: string) => `/cohort/${id}`;
export const FILTER_COHORT_BY_ID = (id: string, attr: string, str: string, offset: number) =>
  `/cohort/${id}/${attr}?search_str=${str}&offset=${offset}`;
export const FETCH_COHORT_PREVIEW_DATA = () => `/cohort/conditions`;
export const FETCH_COHORT_SURVEY_COUNT = () => `/cohort/survey/count`;

export const FETCH_USER_ACTIVITY_FEED = (id: string) => `/cohort/user/${id}/details`;

export const EDIT_COHORT = (id: string) => `/cohort/${id}`;
export const DELETE_COHORT = (id: string) => `/cohort/${id}/delete`;

export const PIN_INSIGHT = (id: string) => `/survey/insight/${id}/pin`;
export const UNPIN_INSIGHT = (id: string) => `/survey/insight/${id}/unpin`;
export const FETCH_INSIGHTS = () => `/survey/insights`;
export const EDIT_PIN_TITLE = (id: string) => `/survey/insight/${id}`;

export const FETCH_MEMBER_DETAILS = () => `/organisation/me`;
export const EDIT_MEMBER_DETAILS = () => `/organisation/member/details`;
export const FETCH_ALL_MEMBERS = () => `/organisation/members`;
export const FETCH_ALL_ORGS = () => `/organisation/orgs`;

export const FETCH_WORKSPACE_MEMBERS = () => `/workspace/members`;
export const FETCH_WORKSPACES_FOR_MEMBER = (id: string) => `/workspace/member/${id}`;
export const EDIT_WORKSPACE_ROLE = (id: string) => `/workspace/member/${id}`;
export const LEAVE_WORKSPACE = (id: string) => `/workspace/member/${id}/leave`;
export const REMOVE_WORKSPACE = (id: string) => `/workspace/member/${id}/delete`;
export const FETCH_WORKSPACES_LIST = () => `/workspace/list`;
export const FETCH_WORKSPACE_MEMBERS_BY_ID = (id: string) => `/workspace/${id}/members`;

export const JOIN_WORKSPACE_ADMIN = (id: string) => `/organisation/workspace/${id}/join`;
export const LEAVE_WORKSPACE_ADMIN = (id: string) => `/organisation/workspace/${id}/leave`;
export const MAKE_MEMBER_ADMIN = (id: string) => `/organisation/member/${id}/make-admin`;
export const MAKE_USER_ADMIN = (id: string) => `/organisation/member/${id}/make-user`;
export const DELETE_USER_ADMIN = (id: string) => `/organisation/member/${id}/delete`;
export const INVITE_USERS_FOR_ALL = (id: string) => `/organisation/workspace/${id}/invites`;
export const REINVITE_USER = (id: string) => `/organisation/member/${id}/reinvite`;

export const EDIT_BILLING_ADDRESS = () => `/billing/address`;
export const FETCH_PORTAL_SESSION_URL = () => `/billing/portal-session`;
export const FETCH_COUNTRY_LIST = () => `/common/country/list`;
export const FETCH_INVOICE_LIST = () => `/billing/invoices`;
export const FETCH_INVOICE_ITEM = (invoice_id: string) => `/billing/invoices/${invoice_id}`;
export const FETCh_ORGANISATION_INFO = () => `/organisation`;
export const PAYMENT_LINK_INIT = (amount: number, curr_plan: string, response_count: number) =>
  `/billing/payment/init?amount=${amount}&curr_plan=${curr_plan}&response_count=${response_count}`;

export const UPDATE_BILLING_PLAN = () => `/billing/update-plan`;

export const CANCEL_SUBSCRIPTION = () => `/billing/payment/cancel`;

export const FETCH_CONNECTION_LIST = () => `/connections`;
export const CREATE_NEW_CONNECTION = (id: string) => `/connections/${id}`;
export const DISABLE_CONNECTION = (id: string) => `/connections/${id}/disable`;

export const FETCH_SPOTLIGHT_DETAILS = () => `/search/details`;
export const FETCH_CHART_DETAILS = () => `/search/chart`;
export const CREATE_DASHBOARD_CHART = () => `/search/chart/new`;

export const CREATE_TAKEAWAY_CHART = () => `/search/takeaway`;
export const REMOVE_TAKEAWAY_FROM_BOARD = (id: string) => `/search/takeaway/${id}/delete`;

export const FETCH_ALL_BOARD = () => `/search/board/all`;
export const CREATE_BOARD = () => `/search/board`;
export const EDIT_BOARD_ACCESS = (board_id: string) => `/search/board/${board_id}/access`;
export const DELETE_BOARD = (id: string) => `/search/board/${id}/delete`;
export const ADD_CHART_TO_BOARD = (id: string) => `/search/board/chart/${id}/update`;
export const REMOVE_CHART_FROM_BOARD = (id: string) => `/search/board/chart/${id}/delete`;
export const FETCH_ALL_CHARTS_IN_BOARD = (id: string) => `/search/board/${id}`;
export const EDIT_BOARD_ORDER_DETAILS = (id: string) => `/search/board/${id}/order`;
export const FETCH_ALL_CHARTS_IN_BOARD_REPORT = (id: string) => `/search/board/${id}/reports`;
export const EDIT_BOARD_DETAILS = (id: string) => `/search/board/${id}`;
export const FETCH_BOARD_LIST_FOR_ID = (id: string) => `/search/chart/${id}/boards`;
export const FETCH_ATTRIBUTE_VALUES = (id: string) => `/search/chart/${id}/attributes`;

export const FETCH_CHART_BY_ID = (id: string) => `/search/chart/${id}`;
export const UPDATE_CHART_BY_ID = (id: string) => `/search/chart/${id}/update`;
export const EDIT_CHART_VIEW = (id: string) => `/search/chart/${id}/view`;

export const CREATE_SLACK_CONNECTION = () => `/slack/init`;
export const UPDATE_SLACK_CHANNEL = () => `/slack/channel`;
export const DISABLE_SLACK_CHANNEL = () => `/slack/channel/stop`;
export const FETCH_SLACK_CHANNEL = () => `/slack/channel/list`;

export const CREATE_WEBHOOK = () => `/connections/webhook`;
export const FETCH_WEBHOOK_LIST = () => `/connections/webhook`;
export const EDIT_WEBHOOK = (id: string) => `/connections/webhook/${id}`;
export const DELETE_WEBHOOK = (id: string) => `/connections/webhook/${id}/delete`;

export const FETCH_ALL_NOTIFICATION = () => `/notification/all`;
export const MARK_NOTIFICATION_AS_READ = (notification_id: string) => `/notification/${notification_id}/read`;
export const MARK_NOTIFICATION_AS_UNREAD = (notification_id: string) => `/notification/${notification_id}/unread`;
export const MARK_NOTIFICATION_ALL_AS_READ = () => `/notification/read/all`;

export const REDIRECT_FEEDBACK_BOARD = (workspace: string) => `/common/redirect?workspace=${workspace}`;

export const AUTH_MEMBER_REDIRECT = (token: string, domain: string) =>
  `/common/redirect/token?token=${token}&domain=${domain}`;

export const FETCH_WORKSPACE_THEMES = () => `/insights/themes/list`;
export const FETCH_WORKSPACE_THEMES_STATUS = () => `/insights/themes/status`;
export const FETCH_ALL_THEME_INSIGHTS = id => `/insights/theme/${id}`;
export const FETCH_INSIGHTS_URL = () => `/insights/url/list`;
export const FETCH_INSIGHTS_IN_REPO = () => `/insights/url/repository`;
export const UPDATE_INSIGHTS_URL = () => `/insights/url`;
export const UPLOAD_CSV_FOR_INSIGHTS = () => `/insights/url/csv`;
export const UPLOAD_CSV_FILES_FOR_INSIGHTS = () => `/insights/url/csv/list`;
export const UPLOAD_CSV_FOR_STATUS = () => `/insights/url/csv/status`;
export const FETCH_INSIGHTS_BY_URL = (source: string) => `/insights/internal/${source}`;
export const DISCONNECT_INSIGHTS_BY_URL = (source: string) => `/insights/url/${source}/remove`;
export const FETCH_EMOTIONS_FROM_TEXTS = () => `/insights/emotion/texts`;
export const FETCH_WORKSPACE_DESC = () => `/insights/workspace/desc`;
export const UPDATE_WORKSPACE_DESC = () => `/insights/workspace/desc`;

export const FETCH_ALL_REPORTS_LIST = () => `/report/all`;
export const FETCH_REPORT_BY_ID = (id: string, should_refresh: boolean) => `/report/${id}?refresh=${should_refresh}`;
export const UPDATE_REPORT_BY_ID = (id: string) => `/report/${id}`;

export const CREATE_FOLDER = () => `/survey/folder/new`;
export const DELETE_FOLDER = (folder_id: string) => `/survey/folder/${folder_id}/delete`;
export const UPDATE_FOLDER = (folder_id: string) => `/survey/folder/${folder_id}/name`;
export const ADD_SURVEY_TO_FOLDER = (folder_id: string) => `/survey/folder/${folder_id}/add`;
export const REMOVE_SURVEY_FROM_FOLDER = (folder_id: string) => `/survey/folder/${folder_id}/remove`;
export const FETCH_ALL_FOLDERS_IN_WORKSPACE = () => `/survey/folder/list`;
export const FETCH_SURVEY_LIST_IN_FOLDER = (
  folder_id: string,
  page_count: number,
  status: string,
  platform: string,
  search_str: string,
) =>
  `/survey/folder/${folder_id}/all?page=${page_count}&status=${status}&platform=${platform}&search_str=${search_str}`;
