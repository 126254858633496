import React from 'react';
import ApiIcon from '@mui/icons-material/Api';

import { ReactComponent as SurveyIcon } from '../images/survey/survey.svg';
import { ReactComponent as ConceptIcon } from '../images/survey/concept.svg';
import { ReactComponent as QuizIcon } from '../images/survey/quiz.svg';

import { ReactComponent as PositiveIcon } from '../images/surveyPerformance/positive-icon.svg';
import { ReactComponent as NegativeIcon } from '../images/surveyPerformance/negative-icon.svg';
import { ReactComponent as NeutralIcon } from '../images/surveyPerformance/neutral-icon.svg';

import { ReactComponent as WarningIcon } from '../images/surveyPerformance/warning-icon.svg';
import { ReactComponent as MonitorIcon } from '../images/surveyPerformance/monitor-icon.svg';
import { ReactComponent as NoactionIcon } from '../images/surveyPerformance/noaction-icon.svg';

import { ReactComponent as BottomLeftIcon } from '../images/settings/bottom-left.svg';
import { ReactComponent as BottomRightIcon } from '../images/settings/bottom-right.svg';
import { ReactComponent as CenterIcon } from '../images/settings/center.svg';

import { ReactComponent as InProgressIcon } from '../images/survey/progress-dot.svg';
import { ReactComponent as PauseIcon } from '../images/survey/pause-icon.svg';
import { ReactComponent as DraftIcon } from '../images/survey/draft-icon.svg';
import { ReactComponent as CompleteIcon } from '../images/survey/complete-icon.svg';

import { ReactComponent as AndroidImg } from '../images/editor/android-icon.svg';
import { ReactComponent as WebImg } from '../images/editor/web-icon.svg';
import { ReactComponent as LinkImg } from '../images/editor/link-icon.svg';
import { ReactComponent as HybridImg } from '../images/editor/hybrid-icon.svg';
import { ReactComponent as FlutterImg } from '../images/editor/flutter-icon.svg';
import { ReactComponent as IosImg } from '../images/editor/ios-icon.svg';
import { ReactComponent as BackendTriggerImg } from '../images/editor/backend-trigger.svg';

import { ReactComponent as CreateSurveyImg } from '../images/survey/create-survey.svg';
import { ReactComponent as CreatePrototypeImg } from '../images/survey/create-prototype.svg';
import { ReactComponent as ChooseTemplateImg } from '../images/survey/choose-template.svg';

import { ReactComponent as BoardLabel1 } from '../images/board/label/label-1.svg';
import { ReactComponent as BoardLabel2 } from '../images/board/label/label-2.svg';
import { ReactComponent as BoardLabel3 } from '../images/board/label/label-3.svg';
import { ReactComponent as BoardLabel4 } from '../images/board/label/label-4.svg';
import { ReactComponent as BoardLabel5 } from '../images/board/label/label-5.svg';
import { ReactComponent as BoardLabel6 } from '../images/board/label/label-6.svg';

import { surveyStatus } from '../styles/colors';

export { ReactComponent as WorkspaceImg } from '../images/workspace-default.svg';
export { ReactComponent as DeleteImg } from '../images/delete-icon.svg';

export const surveyDropdownOptions = {
  draft: ['Archive Survey'],
  paused: ['Complete Survey', 'Archive Survey'],
  completed: ['Archive Survey'],
  live: ['Pause Survey', 'Complete Survey', 'Archive Survey'],
  archived: ['Archive Survey'],
};

export const platforms = [
  { name: 'Android', value: 'android', sublabel: '', quiz_enabled: false },
  { name: 'iOS', value: 'ios', sublabel: '', quiz_enabled: false },
  { name: 'Web', value: 'web', sublabel: '', quiz_enabled: false },
  { name: 'Link', value: 'link', sublabel: '', quiz_enabled: true },
  { name: 'React Native', value: 'hybrid', sublabel: '', quiz_enabled: true },
  { name: 'Flutter', value: 'flutter', sublabel: '', quiz_enabled: false },
  { name: 'API', value: 'api', sublabel: '', quiz_enabled: false },
];

export const platformIcon = {
  android: <AndroidImg />,
  ios: <IosImg />,
  link: <LinkImg />,
  web: <WebImg />,
  hybrid: <HybridImg />,
  flutter: <FlutterImg style={{ width: '12px', height: '12px' }} />,
  api: <ApiIcon />,
};

export const statusData = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'live',
    label: 'Live',
  },
  {
    value: 'completed',
    label: 'Completed',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
  {
    value: 'paused',
    label: 'Paused',
  },
  {
    value: 'archived',
    label: 'Archived',
  },
];

export const platformData = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'android',
    label: 'Android',
  },
  {
    value: 'ios',
    label: 'iOS',
  },
  {
    value: 'link',
    label: 'Link',
  },
  {
    value: 'web',
    label: 'Web',
  },
  {
    value: 'hybrid',
    label: 'React Native',
  },
  {
    value: 'flutter',
    label: 'Flutter',
  },
];

export const surveyRepeatFrequency = [
  {
    value: 'show_once',
    label: ' Display only once (never repeat)',
  },
  {
    value: 'show_till_response',
    label: 'Display survey until user responds',
    field_label: ', retry every',
  },
  {
    value: 'show_till_complete',
    label: 'Display until every survey question is answered',
    field_label: ', retry every',
  },
  {
    value: 'show_recur',
    label: 'Recurring survey',
    field_label: ', users can retake every',
  },
];

export const envData = [
  {
    value: 'production',
    label: 'Production',
  },
  {
    value: 'staging',
    label: 'Staging',
  },
];

export const surveyTypes = [
  {
    value: 'pause',
    label: 'Pause Survey',
  },
  {
    value: 'complete',
    label: 'Complete Survey',
  },
  {
    value: 'archive',
    label: 'Archive Survey',
  },
];

export const startTemplate = [
  {
    title: 'Create a micro-survey',
    subTitle: 'Choose from a library of goal focused templates',
    buttonText: '+ Choose template',
    path: '/templates',
    isPath: true,
  },
  {
    title: 'Integrate your app & define triggers',
    subTitle:
      'Install the blitzllama SDK in your app and add events to trigger your surveys at key moments of the user’s journey',
    buttonText: '+ View documentation',
    path: 'https://documentation.blitzllama.com/',
    isPath: false,
  },
  {
    title: 'Collect & auto-analyze responses',
    subTitle:
      'Launch the survey in your app directly from the dashboard and we’ll help you sort through all of the responses automatically',
    buttonText: '+ Choose template',
    path: '/templates',
    isPath: true,
  },
];

export const previewTheme = [
  {
    value: 'white',
    label: 'White',
    bg_color: '#FFFFFF',
    text_color: '#000000',
    option_bg_color: '#FFFFFF',
    border_color: '#E8E8E8',
    highlight_color: '#4C69FE',
    cta_text_color: '#FFFFFF',
  },
  {
    value: 'summer',
    label: 'Summer',
    bg_color: '#FBF4E7',
    text_color: '#1C0A73',
    option_bg_color: '#FFFFFF',
    border_color: '#E8E8E8',
    highlight_color: '#1C0A73',
    cta_text_color: '#FFFFFF',
  },
  {
    value: 'violet',
    label: 'Violet',
    bg_color: '#FCF9FF',
    text_color: '#000000',
    option_bg_color: '#FFFFFF',
    border_color: '#E8E8E8',
    highlight_color: '#655BDE',
    cta_text_color: '#FFFFFF',
  },
  {
    value: 'black',
    label: 'Black',
    bg_color: '#0F0F0F',
    text_color: '#FFFFFF',
    option_bg_color: '#0A0A0A',
    border_color: '#3B3B3B',
    highlight_color: '#FFFFFF',
    cta_text_color: '#000000',
  },
  {
    value: 'purpleberry',
    label: 'Purple Berry',
    bg_color: '#251A2D',
    text_color: '#FFFFFF',
    option_bg_color: '#281D31',
    border_color: '#574162',
    highlight_color: '#FFFFFF',
    cta_text_color: '#000000',
  },
  {
    value: 'moonlight',
    label: 'Moonlight',
    bg_color: '#212338',
    text_color: '#FFFFFF',
    option_bg_color: '#212237',
    border_color: '#35374A',
    highlight_color: '#FFFFFF',
    cta_text_color: '#212338',
  },
];

export const themeVariants = [
  {
    value: 'default',
    label: 'Original (with borders)',
  },
  {
    value: 'borderless',
    label: 'Clean (minimal borders)',
  },
];

export const getRandomizeType = (options: Array<any>) => {
  const hide_randomize = options.some(o => o.has_text_input);
  if (hide_randomize) {
    return { in_set: 'Maintain option order', randomize_but_last: 'Randomize all but last option' };
  }
  return {
    in_set: 'Maintain option order',
    randomize: 'Randomize all options',
    randomize_but_last: 'Randomize all but last option',
  };
};

export const maxOptionsList = {
  '-1': 'Unlimited',
  '1': 'One',
  '2': 'Two',
  '3': 'Three',
  '4': 'Four',
  '5': 'Five',
};

export const attrFunctions = [
  {
    type: 'string',
    options: [
      { label: 'Includes', value: 'in_list' },
      { label: 'Does not include', value: 'not_in_list' },
    ],
  },
  {
    type: 'number',
    options: [
      { label: 'Equal to', value: 'eq' },
      { label: 'Not Equal to', value: 'neq' },
      { label: 'Greater than', value: 'gt' },
      { label: 'Greater than or Equal to', value: 'gte' },
      { label: 'Lesser than', value: 'lt' },
      { label: 'Lesser than or Equal to', value: 'lte' },
    ],
  },
  {
    type: 'boolean',
    options: [{ label: 'Equal to', value: 'eq' }],
  },
  {
    type: 'date',
    options: [
      { label: 'Date Before', value: 'lt' },
      { label: 'Date After', value: 'gt' },
    ],
  },
];

export const sentimentIcon = {
  positive: <PositiveIcon />,
  negative: <NegativeIcon />,
  neutral: <NeutralIcon />,
};

export const actionTheme = {
  take_action: '#AF4155',
  no_action: '#788287',
  monitor: '#FF7E62',
};

export const actionIcon = {
  take_action: <WarningIcon />,
  monitor: <MonitorIcon />,
  no_action: <NoactionIcon />,
};

export const previewDataType = [
  {
    value: 'string',
    label: 'string',
  },
  {
    value: 'number',
    label: 'number',
  },
  {
    value: 'date',
    label: 'date',
  },
  {
    value: 'boolean',
    label: 'boolean',
  },
];

export const userRole = [
  {
    value: 'editor',
    label: 'Editor',
  },
  {
    value: 'viewer',
    label: 'Viewer',
  },
];

export const teamUserAction = [
  {
    value: 'admin',
    label: 'Make Admin',
  },
  {
    value: 'remove',
    label: 'Remove User',
  },
];

export const memberJobRole = [
  {
    value: 'developer',
    label: 'Developer',
  },
  {
    value: 'designer',
    label: 'Designer',
  },
  {
    value: 'product',
    label: 'Product Manager',
  },
  {
    value: 'marketing',
    label: 'Marketing',
  },
  {
    value: 'analyst',
    label: 'Data Analyst',
  },
  {
    value: 'business',
    label: 'Business Development',
  },
  {
    value: 'others',
    label: 'Others',
  },
];

export const surveyPositions = [
  {
    img: <BottomLeftIcon />,
    text: 'Bottom left corner',
    value: 'bottomleft',
  },
  {
    img: <BottomRightIcon />,
    text: 'Bottom right corner',
    value: 'bottomright',
  },
  {
    img: <CenterIcon />,
    text: 'Center',
    value: 'center',
  },
];

export const filterResponses = [
  {
    label: 'Last 1 hour',
    value: 1,
  },
  {
    label: 'Last 3 hours',
    value: 3,
  },
  {
    label: 'Last 24 hours',
    value: 24,
  },
  {
    label: 'Last 3 days',
    value: 72,
  },
  {
    label: 'Last 7 days',
    value: 168,
  },
  {
    label: 'All responses',
    value: -1,
  },
];

export const filterLanguages = [
  {
    label: 'English translation',
    value: 'English translation',
  },
  {
    label: 'Original language',
    value: 'Original language',
  },
];

export const surveyStatusFeature = {
  live: {
    icon: <InProgressIcon />,
    title: 'In progress',
    color: surveyStatus.activeGreen,
  },
  draft: {
    icon: <DraftIcon />,
    title: 'Draft',
    color: surveyStatus.blueGreen,
  },
  completed: {
    icon: <CompleteIcon />,
    title: 'Completed',
    color: surveyStatus.darkPurple,
  },
  paused: {
    icon: <PauseIcon />,
    title: 'Paused',
    color: surveyStatus.darkGrey,
  },
  archived: {
    icon: '',
    title: 'Archived',
    color: surveyStatus.lightOrange,
  },
};

export const emojisForFeedback = [
  {
    value: 1,
    icon: 'https://cdn.blitzllama.com/survey/assets/emoji-1.png',
  },
  {
    value: 2,
    icon: 'https://cdn.blitzllama.com/survey/assets/emoji-2.png',
  },
  {
    value: 3,
    icon: 'https://cdn.blitzllama.com/survey/assets/emoji-3.png',
  },
  {
    value: 4,
    icon: 'https://cdn.blitzllama.com/survey/assets/emoji-4.png',
  },
  {
    value: 5,
    icon: 'https://cdn.blitzllama.com/survey/assets/emoji-5.png',
  },
];

export const baseCustomCSS = {
  'font-family': 'Inter',
};

export const configureResponseData = [
  {
    title: 'Unlimited',
    value: 't',
  },
  {
    title: 'Maximum of',
    value: 'f',
  },
];

export const triggerDetails = {
  android: {
    icon: <AndroidImg />,
    title: 'Android',
  },
  ios: {
    icon: <IosImg width="13px" height="16px" />,
    title: 'iOS',
  },
  web: {
    icon: <WebImg />,
    title: 'Web',
  },
  hybrid: {
    icon: <HybridImg />,
    title: 'React Native',
  },
  flutter: {
    icon: <FlutterImg style={{ width: '15px', height: '15px' }} />,
    title: 'Flutter',
  },
  backend_event: {
    icon: <BackendTriggerImg />,
    title: 'Backend Event',
  },
};

export const templateChips = [
  {
    title: 'All',
    value: 'all',
  },
  {
    title: 'Surveys',
    value: 'survey',
  },
  {
    title: 'Concept tests',
    value: 'concept',
  },
];

export const launchResearchTypes = [
  {
    type_value: 'survey',
    title: 'Create new survey',
    icon: <SurveyIcon />,
  },
  {
    type_value: 'prototype',
    title: 'Create new  concept test',
    icon: <ConceptIcon />,
  },
  {
    type_value: 'quiz',
    title: 'Create new  quiz',
    icon: <QuizIcon />,
  },
  {
    type_value: 'templates',
    title: 'Choose from templates',
    icon: <ChooseTemplateImg style={{ marginLeft: '5px' }} />,
  },
];

export const sliderValues = {
  0: { title: '', value: 0, monthlyPrice: 0, quarterlyPrice: 0 },
  10: { title: '5k', value: 5000, monthlyPrice: 100, quarterlyPrice: 85 },
  20: { title: '10k', value: 10000, monthlyPrice: 200, quarterlyPrice: 170 },
  30: { title: '20k', value: 20000, monthlyPrice: 325, quarterlyPrice: 275 },
  40: { title: '50k', value: 50000, monthlyPrice: 500, quarterlyPrice: 425 },
  50: { title: '75k', value: 75000, monthlyPrice: 1000, quarterlyPrice: 850 },
  60: { title: '100k', value: 100000, monthlyPrice: 1250, quarterlyPrice: 1065 },
};

export const billingPlanDetails = [
  {
    plan_name: 'free',
    title: 'Starter',
    discount_price: '',
    price: 'Free forever',
    desc: 'To help you get started with product research.',
    features: ['500 monthly responses', 'Up to 5k MTUs', '1 live survey, quiz or concept tests'],
    button_text: '',
  },
  {
    plan_name: 'team',
    title: 'Growth',
    price: '$100/mn',
    discount_price: '$75/mn',
    discount: '25% discount for the 1st year',
    desc: 'For teams scaling their product research capabilities.',
    features: [
      'Unlimited responses',
      'Upto 20k MAUs',
      'Unlimited live surveys, quizzes and concept tests',
      'Remove Blitzllama branding',
    ],
    button_text: 'Switch to Growth plan',
  },
  {
    plan_name: 'company',
    title: 'Scale',
    price: 'Custom plan',
    discount_price: '',
    discount: 'Custom discounts for all plans',
    desc: 'For products with >20k MAUs & established user research processes.',
    features: [
      'Everything in Growth plan',
      'Extensive customizations',
      'Data export integrations',
      'Advanced security',
      'Feature request priority',
    ],
    button_text: 'Book now',
  },
];

export const rtlLanguages = ['ar', 'ur', 'fa', 'he'];

export const propertyDataTypes = ['number', 'string', 'date', 'boolean'];

export const promptTypeData = [
  {
    value: 'evaluative',
    label: 'Evaluative study',
  },
  {
    value: 'generative',
    label: 'Generative study',
  },
];

export const shouldCloseSurveyData = [
  {
    value: true,
    label: 'Close Survey',
  },
  {
    value: false,
    label: 'Keep Survey',
  },
];

export const timeZones = [
  {
    value: 'America/Los_Angeles',
    label: 'America/Los_Angeles',
  },
  {
    value: 'America/New_York',
    label: 'America/New_York',
  },
  {
    value: 'America/Sao_Paulo',
    label: 'America/Sao_Paulo',
  },
  {
    value: 'Europe/London',
    label: 'Europe/London',
  },
  {
    value: 'Africa/Lagos',
    label: 'Africa/Lagos',
  },
  {
    value: 'Africa/Cairo',
    label: 'Africa/Cairo',
  },
  {
    value: 'Asia/Karachi',
    label: 'Asia/Karachi',
  },
  {
    value: 'Asia/Calcutta',
    label: 'Asia/Calcutta',
  },
  {
    value: 'Asia/Jakarta',
    label: 'Asia/Jakarta',
  },
  {
    value: 'Asia/Manila',
    label: 'Asia/Manila',
  },
  {
    value: 'Asia/Singapore',
    label: 'Asia/Singapore',
  },
];

export const boardLabelImgs = {
  0: <BoardLabel1 width={'24px'} height={'24px'} />,
  1: <BoardLabel2 width={'24px'} height={'24px'} />,
  2: <BoardLabel3 width={'24px'} height={'24px'} />,
  3: <BoardLabel4 width={'24px'} height={'24px'} />,
  4: <BoardLabel5 width={'24px'} height={'24px'} />,
  5: <BoardLabel6 width={'24px'} height={'24px'} />,
};

export const userCohortConditions = [
  {
    label: 'in',
    value: 'in_list',
  },
  {
    label: 'not in',
    value: 'not_in_list',
  },
];

export const emptyReportTexts = {
  'Tech Report':
    'No tech issues or bugs to report yet. Wait for more feedback or connect additional data sources to uncover similar insights.,Smooth sailing—no tech issues or bugs to squash! Check back later or plug in more data sources.',
  'Revenue Opportunities':
    'No revenue opportunities identified yet.Wait for more feedback or connect additional data sources to uncover similar insights., No revenue opportunities spotted yet! Let’s wait for feedback to roll in or connect additional data sources to uncover similar insights.',
  'Feature Requests Tracker':
    'No feature requests so far! Either your users love it as is, or feedback is still en route. Try connecting additional data sources to ensure we’re capturing all your users’ input.',
  'Top Product Issues':
    'No product issues, No product issues found yet. Wait for more feedback or connect additional data sources to uncover similar insights.,Your product seems to be acing it—no product issues to report! Keep an eye out or feed us more data to dig deeper.',
  'Daily Report':
    'No daily reports, Nothing to report for today. Wait for more feedback or connect additional data sources to uncover similar insights.,All quiet on the data front today. No reports to show yet. Stay tuned or add more sources for updates!',
  'Weekly Report':
    'No weekly reports, Nothing to report from the last 7 days. Wait for more feedback or connect additional data sources to uncover similar insights.,A trendless week so far—no reports from the past 7 days. Let’s wait it out or add more feedback sources for updates!',
  'Monthly Report':
    'No Monthly reports, Nothing to report from the last 30 days. Wait for more feedback or connect additional data sources to uncover similar insights.,Quiet month so far—no topics detected. Check back later or connect more data sources to uncover insights!',
};
