import React, { useState, useEffect, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  Snackbar,
} from '@mui/material';

import filterImg from '../../images/surveyPerformance/filter-icon.svg';
import emptyStateImg from '../../images/emptyState/empty-themes.svg';
import { StyledTableRow } from '../common/tableStyles';
import { IInsightStore, IWorkspaceTheme } from '../../types/insight';
import InsightTopicDrawer from './InsightTopicDrawer';

import { dateRangeQuery } from '../../utils/DateUtil';
import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { RootState } from '../../store';
import { DateFilter } from '../spotlight/DateFilter';
import { fetchWorkspaceThemes, getInsightsStatus } from '../../services/insightService';
import { ColoredPill, insightStyles } from './InsightStyle';
import { sourceInsightImgs } from '../../static/connectionData';
import { strongEmotions } from '../../static/insightData';
import _ from 'lodash';
import { AlertLoadUtil } from '../../utils/AlertUtil';
import { FilterOptions } from './FilterOptions';
import { capitalizeFirstLetter } from '../../utils/stringUtil';

export default function InsightComponent() {
  const classes = insightStyles();

  const [isLoading, setLoading] = useState(true);
  const [rightState, setRightState] = React.useState(false);
  const [themeItem, setThemeItem] = React.useState<IWorkspaceTheme>();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [searchString, setSearchString] = useState('');
  const [insightStore, setInsightStore] = useState<IInsightStore>({
    sentiment_store: [],
    theme_store: [],
    emotion_store: [],
    source: '',
  });
  const [dateRange, setDateRange] = React.useState({
    type: 'days',
    days_count: 30,
    time_from: '',
    time_to: '',
  });
  const [themes, setThemes] = useState<Array<IWorkspaceTheme>>([]);
  const [sources, setSources] = useState<Array<string>>([]);
  const [snackBar, showSnackBar] = React.useState<boolean>(false);

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'prioritise tab viewed', {});
      if (tracking_info && !tracking_info.show_discovery) {
        window.location.href = '/';
      }
    }
  }, [tracking_info]);

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setRightState(!rightState);
    setThemeItem(undefined);
  };

  const fetchThemes = async (initialRun: boolean) => {
    if (initialRun) {
      return;
    }

    setLoading(true);

    const results = await fetchWorkspaceThemes({ ...dateRange, source: insightStore.source });
    if (results && results.data) {
      const { themes = [], sources = [] } = results.data;
      setThemes(themes);
      setSources(sources);
    }
    setLoading(false);
  };

  const handleDateChanges = (range_type: string, field_name: string, value: string | number) => {
    const curr_date_query = dateRange;
    curr_date_query['type'] = range_type;
    if (field_name === 'days_count') {
      curr_date_query[field_name] = +value;
      const { time_to, time_from } = dateRangeQuery(dateRange);
      setDateRange({
        ...curr_date_query,
        time_to,
        time_from,
      });
      return;
    } else {
      curr_date_query[field_name] = value;
    }
    setDateRange({
      ...curr_date_query,
    });
  };

  useEffect(() => {
    fetchThemes(false);
  }, [dateRange]);

  useEffect(() => {
    let check = true;
    const interval = setInterval(async () => {
      if (check) {
        const config = await getInsightsStatus();
        if (config === 'loading') {
          showSnackBar(true);
        } else {
          await fetchThemes(true);
          showSnackBar(false);
          check = false;
          clearInterval(interval);
        }
      }
    }, 10 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    let filtered_themes = themes;
    const { sentiment_store, theme_store, emotion_store } = insightStore;
    if (sentiment_store.length > 0) {
      const sentiments = sentiment_store.map(s => s.value);
      filtered_themes = filtered_themes.filter(f => sentiments.includes(f.sentiment));
    }

    if (theme_store.length > 0) {
      const themes = theme_store.map(s => s.value);
      filtered_themes = filtered_themes.filter(f => themes.includes(f.theme_name));
    }

    if (emotion_store.length > 0) {
      const emotions = emotion_store.map(s => s.value);

      filtered_themes = filtered_themes.filter(f => {
        const intersection_themes = emotions.filter(e => f.tags.includes(e));
        return intersection_themes.length > 0;
      });
    }

    const total_count = filtered_themes.reduce((a, b) => +a + +b.count, 0);
    return (
      <>
        {themeItem && rightState && (
          <InsightTopicDrawer
            toggleDrawer={toggleDrawer}
            themeItem={themeItem}
            dateRange={dateRange}
            rightState={rightState}
            source={insightStore.source}
          />
        )}

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackBar}
          onClose={() => {
            showSnackBar(false);
          }}
          message="Hold on, we are working on gathering the insights"
          key={'top' + 'center'}
          action={
            <React.Fragment>
              <CircularProgress className={classes.stickyLoading} size={16} />
            </React.Fragment>
          }
        />

        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant="h2">Prioritized Insights</Typography>
          <div>
            <DateFilter date_range={dateRange} handleDateChanges={handleDateChanges} hide1D={true} />
            <Button
              className={classes.filterButton}
              style={{
                color: 'black',
                marginLeft: 10,
                height: '45px',
              }}
              onClick={() => {
                setOpenFilter(true);
              }}
            >
              <Box display={'flex'} alignItems={'center'}>
                <FilterOptions
                  insightStore={insightStore}
                  setInsightStore={setInsightStore}
                  openFilter={openFilter}
                  setOpenFilter={setOpenFilter}
                  themes={themes}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  onClickHandler={() => fetchThemes(false)}
                />
                <img src={filterImg} alt="" />
                <Typography variant="subtitle1" fontWeight={700} ml={1}>
                  Filter
                </Typography>
              </Box>
            </Button>
          </div>
        </Box>

        <Box display={'flex'} alignItems={'center'}>
          {sources.map(s => (
            <>
              <img src={sourceInsightImgs[s]} height={14} />
              <Typography
                variant={'subtitle2'}
                ml={0.5}
                mr={1.5}
                color={'gray'}
                style={{ borderBottom: '1px dotted #000', textDecoration: 'none' }}
              >
                {capitalizeFirstLetter(s)}
              </Typography>
            </>
          ))}
        </Box>

        <Box display={'flex'} justifyContent={'flex-end'}>
          <Typography variant={'subtitle2'} color={'gray'}>
            Count: {total_count}
          </Typography>
        </Box>

        <Grid container pt={3}>
          {filtered_themes.length === 0 ? (
            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <Box pt={25} style={{ textAlign: 'center' }}>
                <img src={emptyStateImg} alt="" />
                <Typography
                  variant={'subtitle1'}
                  maxWidth={250}
                  textAlign={'center'}
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                >
                  Add a feedback source and wait while we build insights for you...
                </Typography>
              </Box>
            </div>
          ) : (
            <TableContainer className={classes.triggerTable}>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Topics</TableCell>
                    <TableCell>Recommendation</TableCell>
                    <TableCell># reports</TableCell>
                    <TableCell>Sentiment</TableCell>
                    <TableCell>Prioritize</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filtered_themes.map((option, index) => {
                    return (
                      <StyledTableRow
                        key={index}
                        onClick={() => {
                          setThemeItem(option);
                          setRightState(true);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <TableCell component="th" scope="row" width={'5%'}>
                          <Typography variant={'subtitle1'}>{index + 1}</Typography>
                        </TableCell>
                        <TableCell width={'15%'}>
                          <Typography variant={'subtitle1'} lineHeight={1.3} ml={1}>
                            {option.theme_name}
                          </Typography>
                        </TableCell>
                        <TableCell width={'40%'}>
                          <Box display="flex" alignItems={'center'}>
                            <Typography variant="subtitle1" pl={0.5}>
                              {option.theme_text}
                            </Typography>
                          </Box>
                        </TableCell>

                        <TableCell width={'12%'}>
                          <Typography variant={'subtitle1'}>
                            {option.count} ({((option.count / total_count) * 100).toFixed(0)}%)
                          </Typography>
                        </TableCell>
                        <TableCell width={'13%'}>
                          <Box>
                            <ColoredPill sentiment={option.sentiment} add_strong={false} />
                          </Box>
                        </TableCell>
                        <TableCell width={'15%'}>
                          <Box display={'flex'} flexWrap={'wrap'}>
                            {option.tags.map(
                              t =>
                                (t.toLowerCase() === 'new' || strongEmotions.includes(t.toLowerCase())) && (
                                  <ColoredPill sentiment={t} add_strong={strongEmotions.includes(t.toLowerCase())} />
                                ),
                            )}
                          </Box>
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </>
    );
  }
}
